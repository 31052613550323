.Page{
    background-color: var(--color2);
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Container {
    width: 85%;
    max-width: var(--content-max-width);
    padding-top: calc(1.5*var(--header-height));
    padding-bottom: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.ContactsList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap:1rem;
    row-gap: 2rem;
    margin-top: 4rem;
}

.ContactItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
    width: auto;
    /* min-width: 280px; */
}

.ContactItemTitleContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .2rem;
}

.ContactItem img {
    width: 20;
    fill: var(--color5);
}

.ContactItemDescriptionContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
}

.MapContainer{
    width: 100%;
}

.Map {
    /* filter: grayscale(100%) hue-rotate(180deg); */
    filter: grayscale(100%) sepia(.2);
    padding: 0px;
    border-left: 0px;
    border-right: 0px;
}