.Page{
    background-color: var(--color2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Container {
    width: 85%;
    max-width: var(--content-max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(1.5*var(--header-height));
    padding-bottom: calc(1.5*var(--header-height));
    gap: 2rem;
    text-align: justify;
}

.List{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.Item{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.TitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: .5rem;
    height: fit-content;
}

.Line {
    height: 1.5rem;
    width: 3px;
    background-color: var(--color6);
}

.Images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
}

.Image {
    width: calc(50% - 1rem);
}

@media only screen
and (max-width: 700px) {
    .Image {
        width: 100%;
    }
}