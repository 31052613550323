.Page{
    width: 100%;
    background-color: var(--color2);
    color: var(--color5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Page .MenuButton {
    color: var(--color2);
}

.ImageContainer {
    position: relative;
    width: 100%;
}

.Image{
    position: relative;
    aspect-ratio: 4;
    background-size: 100%;
    background-position: center;
    z-index: 0;
    padding-top: 8%;
    width: 100%;
}

.Cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 1;
}

.Container {
    width: 85%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0 4rem 0;
    gap: 3rem;
}

.Underlined::after {
    /* background-color: var(--color3); */
    background-color: var(--color6);
}

.List {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;
}

.Item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
    /* max-width: 900px; */
}

.IconContainer{
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Icon {
    width: 100%;
    fill: var(--color5);
}

.ItemContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 120px);
}

@media only screen and (max-width: 640px) {
    .Page h1 {
        font-size: 2.25rem;

    }

    .Page h4 {
        /* font-size: 1rem; */
    }

    .Container {
        align-items: flex-start;
    }

    .Item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ItemContent {
        align-items: center;
        width: 100%;
    }
}