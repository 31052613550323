:root{
  --color1: #1A1411;
  --color2: #615B50;
  --color3: #8C837A;
  --color4: #E8D8B6;
  --color5: #E6E5D7;
  --color6: #C8994F;
  --color7: #8C5E2B;

  --content-max-width: 1200px;

  --header-height: 100px;

  --small-text: 0.875rem;
}

h1 {
  font-size: 3rem; /* 64px */
  margin: 0;
  font-weight: 600;
}

h2 {
  font-size: 2rem; /* 32px */
  margin: 0;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem; /* 24px */
  margin: 0;
  font-weight: 600;
}

h4 {
  font-size: 1.25rem; /* 20px */
  margin: 0;
  font-weight: 600;
}

h5 {
  font-size: 1rem; /* 16px */
  margin: 0;
  font-weight: 600;
}

h6 {
  font-size: 0.875rem; /* 14px */
  margin: 0;
  font-weight: 600;
}

.Underlined {
  display: inline-block; /* Garante que o sublinhado acompanhe o tamanho do título */
  position: relative;
  width: fit-content;
}

.Underlined::after {
  content: "";
  position: absolute;
  bottom: 0;            /* Posiciona a linha no fundo do texto */
  left: 0;              /* Inicia a linha do começo do h2 */
  width: 75%;           /* Define o comprimento do sublinhado como 60% */
  height: 2px;          /* Espessura da linha */
  background-color: var(--color6); /* Cor do sublinhado */
}

.Button {
  background-color: var(--color6);
  color: var(--color5);
  border: none;
  font-size: 1rem;
  font-weight: 600;
  padding: .5rem 1rem;
  border-radius: .3rem;
  z-index: inherit;
  cursor: pointer;
}

html{
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color1);
  color: var(--color5);
  height: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

#root{
  height: 100%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

a {
  border: none;
  text-decoration: none;
  color: inherit;
  /* color: var(--color5); */
}
