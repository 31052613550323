.Page{
    width: 100%;
    min-height: 100%;
    /* min-height: calc(100vh - var(--header-height)); */
    background-color: var(--color2);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Content{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    width: 85%;
    padding-top: calc(1.5*var(--header-height));
    padding-bottom: var(--header-height);
    /* min-height: calc(100vh - var(--header-height)); */
    flex-grow: 1;
    max-width: var(--content-max-width);
    margin: 0 5rem;
}

.Text{
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: justify;
}

.Text h1 {
    margin-bottom: 1rem;
}

.Image{
    width: 30%;
    margin-top: 2rem;
}

.Image img{
    width: 100%;
}



@media only screen
and (max-width: 900px) {
    .Content{
        flex-direction: column;
        /* width: 90%; */
    }
    .Text{
        order: 2;
        margin-top: 0rem;
        width: 100%;
    }
    
    .Image{
        order: 1;
        margin-top: 0rem;
        width: 100%;
    }
}