.Page{
     background-color: #3E3D3D;
     background-image: url('../../assets/images/marca-dagua.png');
     background-repeat: no-repeat;
     background-size: cover;

     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     color: #996D39;
     gap: 20px;
}

#LogoImage {
    width: 70%;
}

.LogoMarcaDagua{
    color: #4B4B4D;
}

.Title{
    font-size: 70%;
}