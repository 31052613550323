.Footer {
    background-color: var(--color1);
    color: (--color5);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: auto; */
    padding: 2rem 0;
}

.FooterContent {
    width: 100%;
    max-width: var(--content-max-width);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 0 2rem;
}

.LogoImage{
    width: 250px;
}

.Menu{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: inherit;
}

.Menu a {
    color: inherit;
}

.Contact{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: inherit;
}

.Item {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.ItemContent{
    display: flex;
    flex-direction: column;
    font-size: var(--small-text);
}
@media only screen and (max-width: 1000px) {
    .Menu{
        display: none;
    }
}