.Page{
    /* color: white; */
}

.Section{
    position: relative;
    /* height: 500px; */
    margin: 0px;
    width: 100%;
    height: max-content;
}

#Banner {
    width: 100%;
    max-height: 100vh;
    aspect-ratio: 2;
    overflow: hidden;
    position: relative;
}

.BannerImage {
    /* height: 100%; Faz a imagem ocupar 100% da altura da div */
    /* width: auto; Mantém a proporção da imagem */
    width: 100%;
    object-fit: cover;
    animation: zoomIn 4s ease forwards;
}

@keyframes zoomIn {
    from {
        transform: scale(1); /* Tamanho original da imagem */
    }
    to {
        transform: scale(1.2); /* Tamanho aumentado da imagem */
    }
}

.QuemSomos {
    width: 100%;
    background-color: var(--color5);
    color: var(--color2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.QuemSomosContainer{
    width: 80%;
    max-width: var(--content-max-width);
    margin: 5rem 0 0 0;
    column-count: 2;
    column-gap: 4rem;
}

.QuemSomosContainer h2 {
    margin-bottom: 1rem;
}

.QuemSomos p {
    margin: 0 0 .25rem 0;
    text-align: justify;
}

.AreasAtuacao {
    width: 100%;
    background-color: var(--color5);
    color: var(--color2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.AreasAtuacaoContainer {
    margin: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: var(--content-max-width);
    /* padding-bottom: 1rem; */
}

.AreasAtuacaoContent {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    margin-top: 3rem;
    width: 100%;
}

.AreasAtuacaoContentRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

}

.AreasAtuacaoContentCard {
    width: 30%;
    text-align: center;
}

.AreasAtuacaoImage {
    width: 100px;
}

.AreasAtuacaoContentCard h4 {
    text-align: center;
}

.NossaEquipeImage{
    position: relative;
    /* height: 450px; */
    aspect-ratio: 3;
    background-size: 100%;
    background-position: top right;
    z-index: 0;
    padding-top: 8%;
}

.Cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 1;
}

.NossaEquipeContent {
    position: relative;
    z-index: 2;
    color: var(--color5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
    gap: 1rem;
    margin-left: 10%;
    max-width: var(--content-max-width);
}

.NossaEquipeContent h5{
    max-width: 60%;
}

@media only screen and (max-width: 920px) {
    .QuemSomosContainer{
        column-count: 1;
    }
    .NossaEquipeImage{
        background-size:cover;
        background-position: 75%;
        padding-top: 15%;
        aspect-ratio: auto;
        height: 350px;
    }
    .Cover {
        background-color: rgba(0, 0, 0, .4);
    }
}

@media only screen and (max-width: 640px) {
    .AreasAtuacaoContent {
        gap: 3rem;
    }
    .AreasAtuacaoContentRow {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }
    .AreasAtuacaoContentCard {
        width: 100%;
        text-align: center;
    }
    
    .AreasAtuacaoImage {
        width: 10rem;
    }
    .NossaEquipeImage{
        display: flex;
        justify-content: center;
        height: 450px;
        min-height: min-content;
    }
    .NossaEquipeContent {
        margin-left: 0%;
    }
    .NossaEquipeContent h5{
        max-width: 100%;
    }
}