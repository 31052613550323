
.Header{
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
}

.Left {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* background-color: red; */
    margin-left: 2rem;
}

.LogoImage {
    height: 50px;
}

.Center {
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Right{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 2rem;
}

.MenuRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    
}

.MenuRow h5 {
    display: inline-block; /* Garante que o sublinhado acompanhe o tamanho do título */
    position: relative;
    width: fit-content;
}

.MenuRow h5.Selected {
    color: var(--color6);
}

.MenuRow h5.Selected::after {
    content: "";
    position: absolute;
    bottom: -.3rem;            /* Posiciona a linha no fundo do texto */
    left: 0;              /* Inicia a linha do começo do h2 */
    width: 80%;           /* Define o comprimento do sublinhado como 60% */
    height: 2px;          /* Espessura da linha */
    background-color: var(--color6); /* Cor do sublinhado */
}

.MenuRow a {
    color: var(--color5);
}

.MenuRow a:hover {
    color: var(--color4);
}

.MenuRowItem {
    
}

.MenuButton {
    display: none;
}

.MenuContent {
    width: 300px;
    height: 100%;
    background-color: var(--color2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.SideMenuHeader{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    color: var(--color5);
    width: 90%;
    margin-left: 5%;
}

.MenuContent .LogoImage {
    margin-left: 8%;
    margin-top: .5rem;
}

.MenuColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    width: 100%;
    margin-top: 1.5rem;
}

.MenuColItem {
    color: var(--color5);
    padding: 0rem 2rem;
}

@media only screen
and (max-width: 1080px) {
    .MenuButton {
        display: flex;
    }
    .MenuRow{
        display: none;
    }
    .LogoImage {
        height: 36px;
    }
}