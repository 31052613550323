.BannerContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    max-height: 100vh;
}

.Cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, .4);
}

.Header {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 11;
}

.HeaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60rem;
    max-width: 90%;
    text-align: center;
    gap: 1rem;
}

.Header h1 {
    /* font-size: 4rem;
    margin: 0; */
    color: var(--color4);
}

.Header h4 {
    /* font-size: 1rem;
    font-weight: normal; */
    margin: 0;
    color: var(--color4);
}

.Header h3 { 
    color: var(--color4);
}

.HeaderTitle {
    color: var(--color6);
}

@media only screen and (max-width: 600px) {
    .Header h1 { 
        font-size: 2.5rem;
    }
    .Header h3 { 
        font-size: 1rem;
    }
  }