.Page{
    background-color: var(--color2);
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Container {
    width: 85%;
    /* max-width: var(--content-max-width); */
    max-width: 1080px;
    padding-top: calc(1.5*var(--header-height));
    padding-bottom: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.Container h4 {
    text-align: justify;
}

.NossaEquipeItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 2rem;
}

.NossaEquipeItem img {
    width: 30%;
    /* min-width: 300px; */
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.NossaEquipeItem p {
    font-size: 1.25rem;
    text-align: justify;
    margin: 0;
    width: 70%;
}

.Name {
    font-weight: 600;
    font-size: 1.25rem;
}

@media only screen
and (max-width: 700px) {

    .Container {
        align-items: flex-start;
    }

    .NossaEquipeItem {
        flex-direction: column;
        align-items: center;
    }
    
    .NossaEquipeItem img {
        width: 100%;
        min-width: auto;
        max-width: 500px;
        order: 0;
    }
    
    .NossaEquipeItem p {
        order: 1;
        width: 100%;
        font-size: 1rem;
    }

}